import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from "../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { container, header, icon, title, text } from './zabka-contest-expired-page.module.scss';
import WarningIcon from "../assets/images/svg/warning.svg";
import { IPage } from '../models/page.model';

import ZabkaContestLayout from "../layouts/zabka-contest-layout";

interface IZabkaContestExpiredPageProps {
    readonly data: {
        page: IPage | null;
    };
}

const ZabkaContestExpiredPage: React.FC<IZabkaContestExpiredPageProps> = ({ data }) => {
    const { t } = useI18next();
    const { page  } = data;

    return (
        <ZabkaContestLayout
            page={page}
            className={container}
        >
            <div className={header}>
                <WarningIcon className={icon} />
                <h1 className={title}>{t('zabka.contest.expired.title')}</h1>
                <p className={text}>{t('zabka.contest.expired.description')}</p>
            </div>
        </ZabkaContestLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default ZabkaContestExpiredPage;
